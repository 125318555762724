/* eslint-disable */

const submit = async (template, planilha, agroindustria) => {
  const submitPlan = planilha.data.map(row => {
    return {
      matricula: row['0.0']?.value,
      nome_fantasia: row['0.1']?.value,
      nome_produtor: row['0.2']?.value,
      estado: row['0.3']?.value,
      cidade: row['0.4']?.value,
      centro: row['0.5']?.value,
      latitude: row['0.6']?.value,
      longitude: row['0.7']?.value,
      inscricao_estadual: row['0.8']?.value,
      matricula_produtor: row['0.9']?.value,
      telefone: row['0.10']?.value,
      bairro: row['0.11']?.value,
      logradouro: row['0.12']?.value,
      numero: row['0.13']?.value,
      complemento: row['0.14']?.value,
      cep: row['0.15']?.value,
      consultor: row['0.16']?.value,
      central: row['0.17']?.value,
      esteio:
        row['0.18']?.value?.toString().toLowerCase() === 'sim' ? true : false,
    }
  })

  return submitPlan
}

const formatData = data => {
  let data_formatada = []
  data.forEach(row =>
    data_formatada.push({
      0.0: { value: row.matricula },
      0.1: { value: row.nome_fantasia },
      0.2: { value: row.nome_produtor },
      0.3: { value: row.estado },
      0.4: { value: row.cidade },
      0.5: { value: row.centro },
      0.6: { value: row.latitude },
      0.7: { value: row.longitude },
      0.8: { value: row.inscricao_estadual },
      0.9: { value: row.matricula_produtor },
      0.1: { value: row.telefone },
      0.11: { value: row.bairro },
      0.12: { value: row.logradouro },
      0.13: { value: row.numero },
      0.14: { value: row.complemento },
      0.15: { value: row.cep },
      0.16: { value: row.consultor },
      0.17: { value: row.central },
      0.18: { value: row.esteio },
    }),
  )
  return data_formatada
}

export default {
  submit,
  formatData,
}
