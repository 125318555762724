<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <crud-list
      v-else
      v-model="fazendas"
      :headers="headers"
      :slots="['item.ATIVO']"
      :actions="
        $user.get().role == 'admin' || $user.get().role == 'consultoria'
          ? actions
          : []
      "
    >
      <template
        v-if="
          $user.get().role === 'admin' || $user.get().role === 'consultoria'
        "
        v-slot:btnCadastro
      >
        <v-row>
          <v-col cols="12" sm="6" md="auto" class="col-xs-2 py-md-0">
            <planilha-upload-dialog
              :template-id="3"
              block
              color="primary"
              @update="getFazendas"
            />
          </v-col>
          <v-col cols="12" sm="6" md="auto" class="col-xs-2 py-md-0">
            <router-link :to="`/${$user.get().role}/fazendas/form`">
              <v-btn color="success"> Nova Fazenda </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.ATIVO`]="{ item }">
        <v-btn
          v-if="item.ATIVO === 'Ativo' || item.ATIVO === 1"
          rounded
          small
          plain
          text
          class="teal lighten-1 white--text"
          @click="updateStatus(item)"
          >{{ item.ATIVO }}</v-btn
        >
        <v-btn
          v-else
          text
          rounded
          small
          plain
          class="red lighten-1 white--text"
          @click="updateStatus(item)"
          >{{ item.ATIVO }}</v-btn
        >
      </template>
    </crud-list>
    <v-dialog
      v-if="fazenda"
      :key="`dialog-${fazenda?.CodFazenda}`"
      v-model="dialog"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="600px"
    >
      <v-card v-if="fazenda" elevation="2" outlined tile>
        <v-btn
          color="black"
          title="Cancelar"
          small
          outlined
          dark
          elevation="2"
          style="position: absolute; top: 5px; right: 5px"
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          {{
            fazenda.agroindustria_ativa
              ? 'Finalizar relação com agroindústria'
              : 'Criar relação com agroindústria'
          }}
        </v-card-title>
        <v-card-text v-if="!loadingDialog">
          <v-row v-if="fazenda.agroindustria_ativa">
            <v-col cols="12">
              <span style="font-size: 18px; font-weight: 600">{{
                'Agroindústria atual: ' +
                fazenda.agroindustria_ativa.pessoajuridica_agroindustria
                  .NomeFantasia
              }}</span>
            </v-col>
            <v-col class="pa-1" cols="6">
              <v-menu
                v-model="menuDataInit"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="
                      moment(fazenda.agroindustria_ativa.DataInicio).format(
                        'DD/MM/YYYY',
                      )
                    "
                    label="Data de inicio"
                    prepend-icon="mdi-calendar"
                    color="teal"
                    readonly
                    :disabled="true"
                    :rules="requiredEndDateTime"
                    v-on="on"
                  />
                </template>
              </v-menu>
            </v-col>
            <v-col class="pa-1" cols="6">
              <v-form ref="form">
                <v-menu
                  v-model="menuDataFim"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="
                        dataFim ? moment(dataFim).format('DD/MM/YYYY') : ''
                      "
                      label="Data de encerramento"
                      prepend-icon="mdi-calendar"
                      color="teal"
                      readonly
                      :rules="requiredEndDateTime"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="dataFim"
                    style="margin: 0px"
                    :max="dataLimite"
                    :min="fazenda?.agroindustria_ativa?.DataInicio"
                    color="teal"
                    locale="pt-br"
                    @input="menuDataFim = false"
                  />
                </v-menu>
              </v-form>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <v-autocomplete
                v-model="selectedAgroindustria"
                autocomplete="autocomplete_off_hack_xfr4!k"
                :items="agroindustrias"
                item-text="pessoa.pessoajuridica.NomeFantasia"
                item-value="CodAgroindustria"
                label="Agroindústria"
                clearable
              />
            </v-col>
            <v-col class="pa-1" cols="12">
              <v-menu
                v-model="menuDataIni"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="moment(dataInicio).format('DD/MM/YYYY')"
                    label="Data de ínicio"
                    prepend-icon="mdi-calendar"
                    color="teal"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="dataInicio"
                  style="margin: 0px"
                  :max="dataLimite"
                  :min="fazenda?.agroindustria_ativa?.DataInicio"
                  color="teal"
                  locale="pt-br"
                  @input="menuDataIni = false"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-row justify="center">
            <v-progress-circular
              style="margin: 46px"
              :size="80"
              color="teal"
              indeterminate
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center" style="margin: 0px">
            <v-btn
              :disabled="loadingDialog"
              elevation="2"
              :color="fazenda.agroindustria_ativa ? 'error' : 'success'"
              @click="
                fazenda.agroindustria_ativa ? finalizaRelacao() : criaRelacao()
              "
            >
              {{
                fazenda.agroindustria_ativa
                  ? 'Finalizar Relação'
                  : 'Criar Relação'
              }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogReordenarRelatorios"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="600px"
    >
      <v-card v-if="fazenda" elevation="2" outlined tile>
        <v-btn
          color="black"
          title="Cancelar"
          small
          outlined
          dark
          elevation="2"
          style="position: absolute; top: 5px; right: 5px"
          icon
          @click="dialogReordenarRelatorios = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title> Reordenar Número de visita dos relatórios </v-card-title>
        <v-card-text v-if="!loadingDialogReordenarRelatorios">
          <v-row>
            <v-col cols="12">
              <span style="font-size: 18px; font-weight: 600">{{
                fazenda.pessoajuridica.NomeFantasia
              }}</span>
            </v-col>
            <v-col class="pa-2" cols="13">
              <div style="height: 30px; width: 100%; font-size: 1.5rem">
                <input
                  id="oldReport"
                  v-model="TypeId"
                  type="radio"
                  value="0"
                  :rules="TypeId"
                  style="height: 1.5rem; width: 1.4rem"
                />
                <label for="oldReport"> Relatório visita geral antigo</label>
                <br />
              </div>

              <div
                v-for="report in reports"
                :key="report.id"
                style="height: 30px; width: 100%; font-size: 1.5rem"
              >
                <input
                  id="oldReport"
                  v-model="TypeId"
                  type="radio"
                  :value="report.id"
                  :rules="TypeId"
                  style="height: 1.5rem; width: 1.4rem"
                />
                <label for="oldReport"> {{ report.name }}</label>
                <br />
              </div>

              <br />
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="fazenda.NumeroVisitaInicial"
                  label="Número da visita inicial"
                  prepend-icon="mdi-order-numeric-ascending"
                  type="number"
                  color="teal"
                  :rules="rulesNumVisita"
                />
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-row justify="center">
            <v-progress-circular
              style="margin: 46px"
              :size="80"
              color="teal"
              indeterminate
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center" style="margin: 0px">
            <v-btn
              :disabled="loadingDialogReordenarRelatorios || !valid"
              elevation="2"
              color="success"
              @click="reordenarRelatorios()"
            >
              Reordenar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from 'moment'
import PlanilhaUploadDialog from '../../components/PlanillhaUpload'
import agroindustryService from '../../services/api/agroindustry'
import reportTypeService from '../../services/api/reportType'
export default {
  components: {
    PlanilhaUploadDialog,
  },
  data() {
    return {
      TypeId: -1,
      valid: true,
      rulesNumVisita: [
        v =>
          (v && v > 0 && v <= 99999) || 'Este valor deve estar entre 0 e 99999',
      ],
      actions: [
        {
          title: 'Editar Fazenda',
          color: 'yellow darken-3',

          click: item =>
            this.$router.push({
              path: 'fazendas/form',
              query: {
                CodFazenda: item.CodFazenda,
              },
            }),

          icon: 'mdi-pencil',
        },
        {
          title: 'Relacionar Agroindústria',
          color: 'primary',
          click: item => this.openDialog(item),
          icon: 'mdi-swap-horizontal-circle',
        },
        {
          title: 'Reordenar Relatórios',
          color: 'green',
          click: item =>
            item.agroindustria_ativa
              ? this.openDialogReordenarRelatorios(item)
              : null,
          icon: 'mdi-order-numeric-ascending',
        },
      ],
      dataFim: this.moment().toISOString(true).substr(0, 10),
      dataInicio: this.moment().toISOString(true).substr(0, 10),
      dataLimite: this.moment().toISOString(true).substr(0, 10),
      menuDataFim: false,
      menuDataIni: false,

      menuDataInit: false,

      dialog: false,
      dialogReordenarRelatorios: false,
      loadingDialog: false,
      loadingDialogReordenarRelatorios: false,
      fazenda: null,
      fazendas: [],
      loading: true,
      selectedAgroindustria: null,
      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Status',
          sortable: true,
          width: 'auto',
          value: 'ATIVO',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Nome',
          align: 'left',
          sortable: true,
          value: 'pessoajuridica.NomeFantasia',
          width: 'auto',
          filterable: true,
        },
        {
          align: 'left',
          sortable: true,
          text: 'Produtor',
          value: 'produtor.Nome',
          width: 'auto',
          filterable: true,
        },
        {
          align: 'left',
          sortable: true,
          text: 'Consultor',
          value: 'consultor.Nome',
          width: 'auto',
          filterable: true,
        },
        {
          align: 'left',
          sortable: true,
          text: 'Agroindústria',
          value:
            'agroindustria_ativa.pessoajuridica_agroindustria.NomeFantasia',
          width: 'auto',
          filterable: true,
        },
      ],
      agroindustrias: [],

      requiredEndDateTime: [
        v =>
          this.validateEndTime(v) ||
          '* Data e hora final não pode ser menor que a inicial',
        v => !!v || '* Obrigatório',
      ],

      reports: [],
    }
  },
  created() {
    if (
      this.$user.get().role === 'admin' ||
      this.$user.get().role === 'consultoria'
    ) {
      this.getAgroindustrias()
      this.loadReports()
    }
    this.getFazendas()
  },

  methods: {
    criaRelacao() {
      if (!this.selectedAgroindustria || !this.dataInicio) {
        this.Swal.fire({
          title: 'Ops!',
          text: 'Selecione um agroindústria e a data de ínicio da relação.',
          icon: 'error',
        })
      } else {
        this.loadingDialog = true
        this.api.save
          .fazendaAgroindustria({
            DataInicio: this.dataInicio,
            CodAgroindustria: this.selectedAgroindustria,
            CodFazenda: this.fazenda.CodFazenda,
          })
          .then(() => {
            this.Swal.fire({
              title: 'Sucesso',
              text: 'A fazenda foi relacionada com a agroindústria.',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            })

            this.loading = true
            this.getFazendas()
            this.dialog = false
            this.loadingDialog = false
          })
          .catch(() => {
            this.loadingDialog = false
          })
      }
    },
    finalizaRelacao() {
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        return this.Toast().fire({
          icon: 'error',
          title: 'Preencha os itens obrigatórios sinalizados com ("*").',
        })
      }
      if (!this.dataFim) {
        this.Swal.fire({
          title: 'Ops!',
          text: 'Selecione a data de encerramento da relacao.',
          icon: 'error',
        })
      } else {
        this.loadingDialog = true
        this.api.update
          .fazendaAgroindustria(
            this.fazenda.agroindustria_ativa.CodFazendaAgroindustria,
            { DataFim: this.dataFim },
          )
          .then(() => {
            this.Swal.fire({
              title: 'Sucesso',
              text: 'A fazenda foi atualizada.',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            })

            this.loading = true
            this.getFazendas()
            this.dialog = false
            this.loadingDialog = false
          })
          .catch(() => {
            this.loadingDialog = false
          })
      }
    },
    reordenarRelatorios() {
      if (this.TypeId == -1) {
        this.Swal.fire({
          title: 'Ops!',
          text: 'Selecione um tipo de relatório para a reordenação.',
          icon: 'error',
        })
      } else {
        this.loadingDialogReordenarRelatorios = true
        this.api.update
          .updateNumeroVisitaRelatorios(this.fazenda.CodFazenda, {
            NumeroVisitaInicial: this.fazenda.NumeroVisitaInicial,
            TypeId: parseInt(this.TypeId),
          })
          .then(() => {
            this.Swal.fire({
              title: 'Sucesso',
              text: 'Os relatórios da fazenda foram reordenados.',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            })

            this.loadingDialogReordenarRelatorios = true
            this.dialogReordenarRelatorios = false
            this.getFazendas()
            this.loadingDialogReordenarRelatorios = false
          })
          .catch(() => {
            this.loadingDialogReordenarRelatorios = false
          })
      }
    },
    openDialog(item) {
      this.fazenda = item
      this.selectedAgroindustria = null
      this.dataInicio = this.moment().toISOString(true).substr(0, 10)
      this.dataFim = this.moment().toISOString(true).substr(0, 10)
      this.dialog = true
    },
    openDialogReordenarRelatorios(item) {
      this.fazenda = item
      this.selectedAgroindustria = null
      this.dialogReordenarRelatorios = true
      this.TypeId = -1
      this.fazenda.NumeroVisitaInicial = '1'
    },
    async getAgroindustrias() {
      const response = await agroindustryService.list()
      this.agroindustrias = response
    },
    getFazendas() {
      this.loading = true
      this.api.get.fazendas().then(data => {
        data = data.map(item => {
          item.ATIVO = this.typeState(item.ATIVO)
          return item
        })
        this.fazendas = data
        this.fazenda = this.fazendas[0]
        this.loading = false
      })
    },

    typeState(status) {
      if (status == '1') {
        return 'Ativo'
      } else {
        return 'Inativo'
      }
    },

    updateStatus(item) {
      if (
        this.$user.get().role === 'admin' ||
        this.$user.get().role === 'consultoria'
      ) {
        this.Swal.fire({
          title: `${item.ATIVO === 'Ativo' ? 'Inativar' : 'Ativar'} Fazenda`,
          text: `Tem certeza que deseja ${
            item.ATIVO === 'Ativo' ? 'inativar' : 'ativar'
          } esse fazenda?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#ff5252',
          confirmButtonText: `Sim, desejo ${
            item.ATIVO === 'Ativo' ? 'inativar' : 'ativar'
          }!`,
          cancelButtonText: 'Cancelar',
          preConfirm: () => {
            this.api.update
              .fazendaState(item.CodFazenda, {
                state: item.ATIVO === 'Ativo' ? 0 : 1,
              })
              .then(res => {
                this.fazendas = this.fazendas.map(fazenda => {
                  if (fazenda.CodFazenda === item.CodFazenda) {
                    fazenda.ATIVO = item.ATIVO === 'Ativo' ? 'Inativo' : 'Ativo'
                  }
                  return fazenda
                })

                this.Swal.fire({
                  title: 'Sucesso',
                  text: `Fazenda ${
                    res.ATIVO ? 'ativado' : 'inativada'
                  } com sucesso`,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 2000,
                })
              })
            return true
          },
        })
      } else {
        this.Swal.fire(
          'Não permitido!',
          'Somente o Admin pode alterar o Status das fazendas.',
          'error',
        )
      }
    },

    validateEndTime(v) {
      if (!this.fazenda?.agroindustria_ativa || !v) return

      const startTime = moment(this.fazenda.agroindustria_ativa.DataInicio)
      const endTime = moment(`${v} 23:59`, 'DD/MM/YYYY')

      if (startTime.isAfter(endTime)) {
        this.Toast().fire({
          icon: 'error',
          title: 'Data e hora final não pode ser menor que a inicial',
        })

        return false
      }

      return true
    },

    async loadReports() {
      try {
        const response = await reportTypeService.list()
        this.reports = response
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar os tipos de relatórios',
        })
      }
    },
  },
}
</script>
