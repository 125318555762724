/* eslint-disable */
class Sender {
  constructor(data = [], quantity = 1) {
    this.data = data
    this.quantity = quantity
    this.position = 0
    this.length = data.length
    this.result = []
    this.erros = []
    this.progress = 0
    this.parts = Math.ceil(data.length / this.quantity)
  }

  async send(form, callback) {
    let request_erros = []

    for (let index = 0; index < this.parts; index++) {
      const submitPlan = this.data.slice(
        this.position,
        this.position + this.quantity,
      )

      this.position += this.quantity

      const waitFor = delay =>
        new Promise(resolve => setTimeout(resolve, delay))
      await waitFor(1000)

      form.progress += 50 / this.parts

      try {
        const r = await callback(submitPlan)
        this.result.push({ ...r.data.data.registers })

        if (r.data.data?.erros?.length > 0) {
          this.erros.push({ ...r.data.data.erros })
        }
      } catch (error) {
        this.request_erros.push(error)
      }

      form.progress += 50 / this.parts
      form.register += submitPlan.length
    }

    return {
      result: { data: this.result, erros: this.erros },
      request_erros,
    }
  }
}

export default Sender
